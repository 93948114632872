@import '@carbon/themes/scss/themes';
$carbon--theme: $carbon--theme--g100;
@include carbon--theme();
@import 'node_modules/carbon-components/scss/components/tabs/tabs';
@import 'node_modules/carbon-components/scss/components/ui-shell/ui-shell';

html, body, #root, #app {
  width: 100%; height: 100%;
  margin: 0; padding: 0;
  font-size: 14px;
  background: #f4f4f4;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  overflow: hidden;
}

.konvajs-content {
  height: 100%;
}

#sidepanel {
  display: none;
  position: fixed;
  top: 42px;
  right: 0;
  width: 50%;
  min-width: 500px;
  height: calc(100% - 42px);
  font-size: 1em;
  z-index: 10;
  background-color: #161616;
}

#sidepanel.show {
  display: block;
}

#sidepanel .actionbar,
#sidepanel .header {
  background-color: #161616;
  color: #f4f4f4;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
}

#sidepanel .header {
  font-size: 3rem;
  font-weight: 300;
  border-bottom: 1px solid #393939;
}

#sidepanel .actionbar {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

#sidepanel .actionbar > div {
  padding: 15px 10px;
  cursor: pointer;
}

#sidepanel .header h1 {
  padding: 20px;
}

#sidepanel .headerinput {
  background: #161616;
  border-width: 0;
  border-collapse: collapse;
  color: #f4f4f4;
  padding: 20px;
  border-bottom: 1px solid #393939;
  font-size: 3rem;
  font-weight: 200;
  width: 100%;
}

svg.closepanel {
  transform: rotate(90deg);
}
